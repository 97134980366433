
import UiButton from '@/components/ui/UiButton.vue';
import { ButtonType, ButtonSize, ButtonFontSize } from '@/enums/button';
import usersApi from '@/services/api/users-api';
import { getTokenViaSocialConnection } from '@/services/api/social-connect-api';
import { useRoute } from 'vue-router';

import UniversalSocialauth, { Providers } from 'universal-social-auth';

import { inject, ref } from 'vue';

import { useToast } from 'vue-toastification';

import { ProderT } from 'universal-social-auth/dist/providers';
import {
  IOverrideOptions,
  IProviderType,
  IProviderName,
  ISocialsList,
} from '@/core/types/social-auth';

interface IProviderButton {
  label: string;
  icon: string;
  provider: IProviderType;
  providerName: IProviderName;
}

export default {
  components: {
    UiButton,
  },
  setup() {
    const route = useRoute();

    const socialAuth: UniversalSocialauth | undefined = inject('socialAuth');
    const socialAuthProviders = inject('socialAuthProviders');

    const toast = useToast();

    const token = route.query.token || null;

    const connectedProviders = ref<ISocialsList>();

    const useAuthProvider = async function (provider: string) {
      const extendProviderData: ProderT = {
        ...(Providers[provider] as ProderT),
      };

      if (token)
        localStorage.setItem('token-for-password-creation', token as string);

      if (socialAuth) {
        socialAuth
          .authenticate(provider, extendProviderData)
          .then((response) => {
            if (response.error) {
              toast.error(response.error_message);
              return;
            }

            if (response.code) {
              handleSocialConnection(response, provider);
            }
          })
          .catch((err: any) => {
            toast.error(err.detail);
          });
      }
    };

    const handleSocialConnection = async (
      responseData: any,
      provider: string
    ) => {
      try {
        await getTokenViaSocialConnection({
          provider,
          code: responseData.code,
          providerData: socialAuthProviders as IOverrideOptions,
        });
        toast.success('Social provider has been connected!');
        await getSocialConnectios();
      } catch (error) {
        if (error instanceof Error) {
          toast.error(error.message);
        } else if (typeof error === 'string') {
          toast.error(error);
        }
      }
    };

    const getSocialConnectios = async () => {
      const res = await usersApi.getSocialConnections();
      connectedProviders.value = res;
    };

    const disconnectSocialConnectios = async (name: IProviderName) => {
      const connectedItem = connectedProviders.value?.find(
        (item) => item.provider === name
      );
      if (!connectedItem) return;
      try {
        await usersApi.removeSocialConnection(connectedItem.id);
        toast.success('Social provider has been disconnected!');
        await getSocialConnectios();
      } catch (err: any) {
        if (typeof err === 'string' || err.detail) {
          toast.error(typeof err === 'string' ? err : err.detail);
        }
      }
    };

    const socialsProviders: IProviderButton[] = [
      {
        label: 'Google',
        icon: require('@/assets/images/social/google.svg'),
        provider: IProviderType.GOOGLE,
        providerName: IProviderName.GOOGLE_OAUTH2,
      },
      {
        label: 'Microsoft',
        icon: require('@/assets/images/social/microsoft.svg'),
        provider: IProviderType.MICROSOFT,
        providerName: IProviderName.MICROSOFT_GRAPTH,
      },
    ];

    const isConnectedProvider = (name: IProviderName) => {
      return connectedProviders.value?.some((item) => item.provider === name);
    };

    const providerHandleAction = (provider: IProviderButton) => {
      try {
        const isConnected = isConnectedProvider(provider.providerName);
        isConnected
          ? disconnectSocialConnectios(provider.providerName)
          : useAuthProvider(provider.provider);
      } catch (e: any) {
        if (typeof e === 'string' || e.detail) {
          toast.error(typeof e === 'string' ? e : e.detail);
        }
      }
    };

    getSocialConnectios();

    return {
      useAuthProvider,
      ButtonType,
      ButtonSize,
      ButtonFontSize,
      disconnectSocialConnectios,
      socialsProviders,
      connectedProviders,
      isConnectedProvider,
      providerHandleAction,
    };
  },
};
