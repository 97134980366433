import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex justify-around space-x-2" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_button = _resolveComponent("ui-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.socialsProviders, (item) => {
      return (_openBlock(), _createBlock(_component_ui_button, {
        key: item.provider,
        onClick: ($event: any) => ($setup.providerHandleAction(item)),
        "button-type": $setup.ButtonType.Outline,
        size: $setup.ButtonSize.Bigger,
        "font-size": $setup.ButtonFontSize.Small,
        rounded: "",
        width: "230",
        type: "button"
      }, {
        before_icon: _withCtx(() => [
          _createElementVNode("img", {
            src: item.icon,
            alt: "",
            class: "ml-3"
          }, null, 8, _hoisted_2)
        ]),
        default: _withCtx(() => [
          _createTextVNode(" " + _toDisplayString($setup.isConnectedProvider(item.providerName) ? 'Disconnect' : 'Connect with') + " " + _toDisplayString(item.label), 1)
        ]),
        _: 2
      }, 1032, ["onClick", "button-type", "size", "font-size"]))
    }), 128))
  ]))
}